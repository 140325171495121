<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <div v-if="results && results.length > 0" class="col-12 text-right">
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                @click.stop="showAll()"
            >
                {{ $t('trip.show_map') }}
            </b-button>
            <b-button
                variant="outline-success"
                size="sm"
                class="result-button"
                @click.stop="exportToExcel()"
            >
                {{ $t('common.download_excel') }}
            </b-button>
        </div>
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(time)="row">
                        {{ toLocalTime(row.item.start_time) }} -  {{ toLocalTime(row.item.end_time) }}
                    </template>
                    <template v-slot:cell(vehicle)="row">
                        {{ row.value.license_plate }} {{ row.value.make }} {{ row.value.vehicle_model }}
                    </template>
                    <template v-slot:cell(spreader_system)="row">
                        {{ getSystemName(row.value) }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(dry_material)="row">
                        {{ getMaterialName(row.item.material_dry) }}
                    </template>
                    <template v-slot:cell(dry_amount)="row">
                        {{ row.item.total_dry_amount }} {{ row.item.material_dry_unit }}
                    </template>
                    <template v-slot:cell(liquid_material)="row">
                        {{ getMaterialName(row.item.material_liquid) }}
                    </template>
                    <template v-slot:cell(liquid_dry_material_mass)="row">
                        {{ row.item.liquid_dry_material_mass }} {{ row.item.liquid_dry_material_mass ? 'kg' : ''}}
                    </template>
                    <template v-slot:cell(liquid_amount)="row">
                        {{ row.item.total_liquid_amount }} {{ row.item.material_liquid_unit }}
                    </template>
                    <template v-slot:cell(info)="row">
                        {{ errorCodeConverter(row.value) }}
                    </template>
                    <template v-slot:cell(map)="row">
                        <div class="spreader-data-actions">
                            <b-tooltip :target="'map'+row.item.id" triggers="hover" placement="top">{{ $t('spreader_data.show_map')}}</b-tooltip>
                            <b-tooltip  :target="'export'+row.item.id" triggers="hover" placement="top">{{ $t('spreader_data.export')}}</b-tooltip>
                            <b-tooltip v-if="row.item.export_status === 1" :target="'remove'+row.item.id" triggers="hover" placement="top">{{ $t('spreader_data.remove_external')}}</b-tooltip>
                            <font-awesome-icon
                                :id="'map'+row.item.id"
                                icon="map"
                                class="row-icon"
                                style="color: #4AAE9B"
                                @click.stop="emitEdit(row.item.id)"
                            />
                            <font-awesome-icon
                                :id="'export'+row.item.id"
                                icon="cloud-upload-alt"
                                class="row-icon"
                                :class="{ failed: row.item.export_status === 2, success: row.item.export_status === 1, unknown: !row.item.export_status}"
                                @click.stop="emitEdit(row.item.id, true)"
                            />
                            <font-awesome-icon
                                v-if="row.item.export_status === 1"
                                :id="'remove'+row.item.id"
                                icon="plug"
                                class="row-icon row-plug-icon"
                                @click.stop="emitRemove(row.item)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import {vehicleHelper} from "@/components/mixins/VehicleMixin";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {utility} from '@/components/mixins/Utils'

export default {
    name: 'SpreaderDataList',
    mixins: [timeUtils, workManagementHelper, vehicleHelper, spreaderDataMixin, utility],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            fields: [
                {key: 'time', label: this.$t('spreader_data.time'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
                {key: 'vehicle', label: this.$t('trip_list.vehicle'), sortable: true},
                {key: 'spreader_system', label: this.$t('spreader_data.spreader_system'), sortable: true},
                {key: 'spreader_id', label: this.$t('spreader_data.spreader'), sortable: true},
                {key: 'dry_material', label: this.$t('spreader_data.dry_material'), sortable: true},
                {key: 'dry_amount', label: this.$t('spreader_data.dry_amount'), sortable: true},
                {key: 'liquid_material', label: this.$t('spreader_data.liquid_material'), sortable: true},
                {key: 'liquid_amount', label: this.$t('spreader_data.liquid_amount'), sortable: true},
                {key: 'liquid_dry_material_mass', label: this.$t('spreader_data.liquid_dry_material_mass'), sortable: true},
                {key: 'info', label: this.$t('common.info'), sortable: true},
                {key: 'map', label: '', sortable: false}
            ],
            loading: false
        }
    },
    methods: {
        getSystemName(system) {
            if(this.spreaderSystems.SYSTEM_FLEET === system) {
                return this.$t('spreader_data.system_fleet')
            }
            if(this.spreaderSystems.SYSTEM_AM === system) {
                return this.$t('spreader_data.system_am')
            }
            if(this.spreaderSystems.SYSTEM_ROUTA === system) {
                return this.$t('spreader_data.system_routa')
            }

            return system
        },
        emitEdit: function (result, openHarjaSender = false) {
            let item = this.results.find(item => result === item.id)
            if (item) {
                this.$emit('rowSelected', item, openHarjaSender)
            }
        },
        emitRemove(itemId) {
            // TODO - Show modal confirmation dialog about removing data from 3rd party service
            this.$emit('removeItem', itemId)
        },
        showAll() {
            this.$emit('showAll')
        },
        exportToExcel() {
            this.$emit('exportToExcel')
        },
    }
}
</script>
<style scoped>
.spreader-data-actions {
    display: flex;
    gap: 1rem;
    padding-left: .5em;
}

.failed {
    color: #9e0c0c;
}

.failed:hover {
    color: #770707;
}


.success {
    color: #7FC000;
}

.success:hover {
    color: #5a8602;
}

.unknown {
    color: #007BFF;
}

.unknown:hover {
    color: #0b4b8f;
}
</style>
