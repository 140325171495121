<template>
    <div >
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header" v-if="!vehicleId">
                <span class="title">
                    {{ $t('trip.trip') }}
                </span>
            </div>
        </b-navbar>

        <vehicle-trips
            v-if="vehicleId"
            :user="user"
            :vehicle-id="vehicleId"
            @search="searchTrips"
            />
        <trip-search
            v-if="isAdmin || isObserver"
            :user="user"
            :pre-selected-contract="preSelectedContract"
            @search="searchTrips"
            @areaGroupSelected="onAreaGroupSelected"
            @areaSelect="customAreaSelection"
        />
        <trip-search-map-viewer
            v-else-if="isMapViewer"
            @search="searchTrips"
        />

        <trip-search-worker
            v-else-if="isWorker"
            :user="user"
            @search="searchTrips"
        />
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <div class="button-container col-sm-12 trip-buttons">
            <b-button
                class="result-button"
                variant="success"
                :disabled="trips.length < 1"
                @click="toggleMap"
            >
                {{ this.$t(showMap ? 'trip_list.hide_map' : 'trip.show_map') }}
            </b-button>
            <b-button
                v-if="showMap"
                class="result-button"
                variant="primary"
                :disabled="trips.length < 1"
                @click="showAllTripsOnMap"
            >
                {{ this.$t('trip_list.show_all_on_map') }}
            </b-button>
            <b-button
                v-if="!isMapViewer"
                class="result-button"
                variant="success"
                :disabled="selectedTrip == null"
                @click="simulateTrip"
            >
                {{ $t('trip.simulate_trip') }}
            </b-button>
            <b-button
                v-if="!isMapViewer"
                variant="outline-success"
                class="result-button"
                :disabled="trips.length < 1"
                @click="exportGeojson"
            >
                {{ $t('trip.download_geojson') }}
            </b-button>
            <b-button
                v-if="!isMapViewer"
                variant="outline-success"
                class="result-button"
                :disabled="trips.length < 1"
                @click="exportExcel"
            >
                {{ $t('trip.download_excel') }}
            </b-button>
            <b-button
                v-if="!isMapViewer"
                variant="outline-primary"
                :disabled="trips.length < 1"
                class="result-button"
                @click="generatePdf"
            >
                {{ $t('observations.details_report') }}
            </b-button>
        </div>
        <div class="trip-results" v-if="trips.length > 0">
            <b-row class="nopads pr-2 pb-3 pl-2">
                <!-- Trip list -->
                <div :class="{'col-sm-6': showMap, 'col-12': !showMap}" class="nopads pb-2 pl-1 pr-1">
                    <div
                        id="tripResultContainer"
                        class="col-sm-12 nopads"
                    >
                        <!-- Summary figures -->
                        <div v-if="totalDuration && totalKilometers" class="col-12 nopads pb-1" style="background: #FFFFFF; box-shadow: #d9d9d9 1px 1px 4px; border-radius: .5em">
                            <div class="col-sm-3 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('trip_list.total_trip_length') + ' ' + '(km)' }}
                                </div>
                                <div class="item-detail-text">
                                    <span>{{ totalKilometers }}</span>
                                </div>
                            </div>
                            <div class="col-sm-9 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('trip_list.total_duration') + ' ' + '(h)' }}
                                </div>
                                <div class="item-detail-text">
                                    <span>{{ totalDuration }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Trip list part with scrollable content -->
                        <div class="col-12 nopads mt-1" style="height: 45em; overflow: auto; background: #FFFFFF; border-radius: .5em; border: 1px #d9d9d9;">
                            <trip-list
                                ref="tripList"
                                :trips="trips"
                                :is-map-viewer="isMapViewer"
                                @rowClicked="showTripOnMap"
                                @downloadGeometry="downloadGeometry"
                                @downloadGeoJson="downloadGeoJson"
                                @downloadPdf="openTripPrintDetailsWithObservations"/>
                        </div>
                    </div>
                </div>
                <!-- Map -->
                <div v-if="showMap" class="col-sm-6 nopads pb-2 pl-1 pr-1">
                    <div class="map-container" style="height: 40em; border-radius: .75em; border: 1px solid #BCBCBC" >
                        <map-container
                            :find-user="false"
                            ref="mapContainer"
                            @onPolylineTap="onPolylineTap"
                            @onMapInfoClosed="onMapInfoClosed"
                            @onMapClicked="mapClicked"
                            @onPolylineHover="onPolylineHover"
                            @onPolylineHoverExit="onPolylineHoverExit"
                            @onMarkerPointerEnter="onMarkerPointerEnter"
                            @onDrag="onDrag"
                            @onDragEnd="onDragEnd"
                            @onMapChanged="mapChanged"
                            :menu-items="menuItems"
                        />
                        <trip-map
                            v-if="map"
                            ref="tripMap"
                            :trip-results="trips"
                            :trip-item="selectedTrip"
                            :map="map"
                            :show-trip-info="false"
                            :area-group="selectedAreaGroup"
                            :custom-area="customAreaSelect"
                            :point-hover="true"
                            @tripClicked="onTripClicked"
                        />
                    </div>
                </div>
            </b-row>
            <!-- Map controls -->
            <div class="map-controls" v-if="showMap">
                <div class="map-controls__list col-12 col-lg-4 nopads mt-2">
                    <measure-distance-map
                        v-if="measureDistanceEnabled"
                        ref="measureDistances"
                        :map="map"
                        :measure-distance-results="measureDistances"
                        :measure-distance-target-item="measureDistanceTargetItem"
                        :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                        :draggable="true"
                        @onRemoveMeasureDistances="removeMeasureDistances"
                    />
                    <measure-road-map
                        v-if="measureRoadEnabled"
                        ref="measureRoad"
                        :map="map"
                        :hide-make-new-observation-button="true"
                        :draggable="true"
                        @close="measureRoadEnabled = false"
                        @confirmSelection="createLineObservation"
                    />
                </div>
            </div>
            <map-layer-selector
                v-if="mapLayerSelector"
                @close="mapLayerSelector = false"
                @onMapSelected="onMapSelected"/>
        </div>
        <trip-with-observations-report v-if="!!this.tripReportTrip" :trip="this.tripReportTrip" @close="closeTripWithObservationsReport" />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import TripMap from './TripMap'
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {geometryMixin} from '@/components/mixins/GeometryMixin';
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import {downloadHelper} from '../mixins/DownloadMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import MeasureDistanceMap from "../map/MeasureDistanceMap";
import MeasureRoadMap from "../map/MeasureRoadMap";
import TripSearchWorker from "./TripSearchWorker";
import TripSearch from "./TripSearch";
import MapContainer from "../map/MapContainer";
import TripList from "./TripList";
import MapLayerSelector from "../map/MapLayerSelector";
import {coordinateConverter} from "@/components/mixins/CoordinateConversionMixin";
import TripSearchMapViewer from "@/components/trip/TripSearchMapViewer";
import VehicleTrips from "@/components/vehicle/tabbed/VehicleTrips.vue";
import {PdfGeneratorManager} from "@/modules/PdfGeneratorManager";
import {PdfGeneratorService} from "@/modules/PdfGeneratorService";
import {StandardFonts} from "pdf-lib";
import TripWithObservationsReport from "@/components/trip/TripWithObservationsReport"


export default {
    name: 'Trips',
    components: {
        VehicleTrips,
        TripSearchMapViewer,
        MapLayerSelector,
        TripList, MapContainer, TripSearch, TripSearchWorker, TripMap, MeasureDistanceMap, MeasureRoadMap, TripWithObservationsReport},
    mixins: [timeUtils, restApi, vehicleHelper, downloadHelper, mapHelper, measureDistanceMixin, coordinateConverter, geometryMixin],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        },
        isMapViewer: {
            type: Boolean,
            default: false
        },
        vehicleId: Number,
        preSelectedContract: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            map: null,
            exportFields: [],
            showMap: false,
            lastParams: null,
            trips: [],
            measureRoadEnabled: false,
            measureDistances: [],
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            showObservationWizardOnCoord: null,
            loading: 0,
            selectedTripId: null,
            selectedAreaGroup: null,
            mapLayerSelector: false,
            mapData: null,
            customAreaSelect: null,
            tripReportTrip: null,
        }
    },
    computed: {
        selectedTrip() {
            return this.trips.find((trip) => trip.id === this.selectedTripId);
        },
        totalKilometers() {
            let totalAmount = 0
            this.trips && this.trips.forEach((trip) => {
                totalAmount += trip.length
            })
            return totalAmount ? totalAmount.toFixed(2) : null
        },
        totalDuration() {
            let totalDuration = 0
            this.trips && this.trips.forEach((trip) => {
                let duration_min = this.getDurationInMinutes(trip.start_time, trip.end_time);
                totalDuration += duration_min
            })
            return totalDuration ? this.getWorkTime(totalDuration) : null
        },
        menuItems() {
            return [
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.measureRoadEnabled ? {
                    text: this.$t('map.measure_road'),
                    onClick: this.initMeasureRoad
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureRoads
                },
                {
                    text: this.$t('map.layers'),
                    onClick: this.showMapLayerSelector
                },
            ]
        }
    },
    methods: {
        syncScroll(source) {
            // Synchronize horizontal scrolling
            if (source === 'top') {
                this.$refs.tableScroll.scrollLeft = this.$refs.topScroll.scrollLeft;
            } else if (source === 'bottom') {
                this.$refs.topScroll.scrollLeft = this.$refs.tableScroll.scrollLeft;
            }
        },

        customAreaSelection: function (data) {
            this.customAreaSelect = data;
        },

        mapClicked: function (coord) {
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }

            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
        },
        onMapInfoClosed: function () {
            if (this.mapVisible) {
                this.$refs.tripMap.onMapInfoClosed()
            }
        },
        async searchTrips(params) {
            this.lastParams = params
            this.lastParams.with_geom = this.showMap ? 1 : 0
            this.lastParams.with_geom = this.showMap ? 1 : 0
            this.lastParams.timestamps = this.showMap ? 1 : 0
            await this.loadTrips(this.lastParams)
        },
        async loadTrips(params) {
            if (this.showMap && this.$refs.tripMap) {
                this.$refs.tripMap.hideTrips();
            }
            this.lastParams = params;
            this.loading++;
            const { data, err } = await this.fetchTrips(params).catch((err) => ({err}));
            this.loading--;
            if (err) {
                if (err.response.status && err.response.status === 500) {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_too_much'))
                } else {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
                }
                return;
            }
            this.trips = data.map((trip) => {
                // Ensure times will have timezone information. If not, then set as UTC!
                trip.start_time = this.setTime(trip.start_time)
                trip.end_time = this.setTime(trip.end_time)
                return trip;
            })
        },

        fetchTrips(params) {
            return this.axios.get(this.tripUrl, {params})
        },

        simulateTrip() {
            this.$refs.tripMap.showMovingCar()
        },

        async fetchTripGeometries() {
            const tripsHaveGeometry = this.trips.some((trip) => trip.geometry);
            if (this.lastParams !== undefined && !tripsHaveGeometry) {
                // Search trips again with geometries
                this.lastParams.with_geom = 1
                this.lastParams.timestamps = 1
                this.lastParams.speed = 1
                await this.loadTrips(this.lastParams);
            }
        },

        async toggleMap(state = null) {
            if (this.showMap === state) return;
            // Toggle state if none given
            if (typeof state !== "boolean") state = !this.showMap;
            this.showMap = state;
            if (this.showMap) {
                await this.fetchTripGeometries()
                await this.$nextTick();
                this.map = this.$refs.mapContainer.getMap()
            } else {
                this.$refs.tripMap.stopUpdateCarPosition()
                this.map = null
                this.selectedTripId = null
                this.$refs.tripList.resetSelection()
                this.measureDistanceEnabled = false
                this.measureRoadEnabled = false
            }
        },

        async showAllTripsOnMap() {
            this.$refs.tripList.resetSelection()
            this.selectedTripId = null
            await this.fetchTripGeometries()
        },

        async exportExcel() {
            this.loading++;
            const { err, ...response } = await this.fetchExcel().catch((err) => ({err}));
            this.loading--;
            if (err) {
                EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
                return;
            }
            this.downloadFile(response, this.$t('trip.report_file_name'))
        },

        async exportGeojson() {
            this.loading++;
            const { err, ...response } = await this.fetchGeojson().catch((err) => ({err}));
            this.loading--;
            if(err) {
                EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
                return;
            }
            this.downloadFile(response, this.$t('trip.trips_json_file_name'))
        },

        fetchGeojson() {
            let params = this.getCommonReportParams(this.lastParams)
            params.with_geom = 1
            return this.axios.get(
                this.tripGeoJsonUrl,
                {
                    params: params,
                    responseType: 'arraybuffer'
                }
            )
        },

        fetchExcel() {
            return this.axios.get(
                this.tripReportUrl,
                {
                    params: this.getCommonReportParams(this.lastParams),
                    responseType: 'arraybuffer'
                }
            )
        },

        onAreaGroupSelected(group) {
            this.selectedAreaGroup = group
        },

        onPolylineTap: function (data) {
            this.selectedTripId = data.id;
            // Pass to visible function
            if (this.showMap) {
                this.$refs.tripMap.onPolylineTap(data)
            }
        },

        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        onPolylineHover: function (data, latlng, index) {
            this.map.removeGeoLabels("pointHoverLabel");
            if(this.selectedTrip && this.selectedTrip && data && index) {
                let time = this.selectedTrip.timestamps.length > 0 ? this.getDateStringHoursMinutesSeconds(this.selectedTrip.timestamps[index]) : ""
                let taskType = this.selectedTrip.order
                let label = ''
                if(time) {
                    label += `<div class="nopads pl-2" style="font-style: italic; color: #949494">${time}</div>`
                }
                label += `<span class="nopads">${taskType}</span><br>`
                this.map.addGeoLabel(latlng.lat, latlng.lng, label, "pointHoverLabel")
            }
        },

        onPolylineHoverExit() {
            this.map.removeGeoLabels("pointHoverLabel");
        },

        onMarkerPointerEnter: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.showObservationTitle(data)
                    break
            }
        },

        onTripClicked(index) {
            this.$refs.tripList.setSelection(index)
        },

        async showTripOnMap(trip) {
            if (trip) {
                await this.toggleMap(true);
                this.selectedTripId = trip.id;
            }
        },

        showMapLayerSelector: function () {
            this.mapLayerSelector = true
        },

        async onMapSelected(layer) {
            this.loading = true
            await this.$refs.mapContainer.setMap(layer);
            await this.$refs.mapContainer.saveMapSettings();
            this.mapLayerSelector = false
            this.loading = false
        },

        mapChanged(map) {
            this.map = map
        },

        async downloadGeoJson(id) {
            let trip = this.trips.find(item => item.id === id);
            if (trip) {
                await this.fetchTripGeometries();
                // Re-assign with geometries
                let trip = this.trips.find(item => item.id === id);
                let geoJsonData = this.getGeometryAsGeoJson(trip);
                if (geoJsonData) {
                    // Generate GeoJSON and download
                    let blob = new Blob([JSON.stringify(geoJsonData)], { type: 'application/json;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'Routa_geometry.geojson');
                    pom.click();
                }
            }
        },
        async openTripPrintDetailsWithObservations(id) {
            let trip = this.trips.find(item => item.id === id)
            if (trip) {
                this.loading++;
                // Load trip geometries
                await this.fetchTripGeometries()
                trip = this.trips.find(item => item.id === id)

                this.tripReportTrip = trip
                this.loading--;
            }
        },

        async downloadGeometry (id) {
            let trip = this.trips.find(item => item.id === id)
            if (trip) {
                await this.fetchTripGeometries()
                // Re-assign with geometries
                let trip = this.trips.find(item => item.id === id)
                let csvData = this.getGeometryAsCsv(trip)
                if (csvData) {
                    // Generate csv and download
                    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'Routa_geometry.csv');
                    pom.click();
                }
            }
        },

        getGeometryAsGeoJson: function (trip) {
            let result = []
            if (trip.geometry) {
                trip.geometry.forEach(point => {
                    result.push([point['x'], point['y']])
                })

            }
            return this.addFeatureCollection(result)
        },

        getGeometryAsCsv: function (trip) {
            let result = ''
            if (trip.geometry) {
                trip.geometry.forEach(point => {
                    let tm35FinCoords = this.wgs84ToTm35Fin(point['x'], point['y'])
                    result += tm35FinCoords[0] + ',' + tm35FinCoords[1] + '\r\n'
                })
            }
            return result
        },

        async generatePdf() {
            this.loading = true
            const pdfGeneratorManager = new PdfGeneratorManager()
            const pdfDoc = await pdfGeneratorManager.createStandardRoutaReportPdf(true, true)
            await PdfGeneratorService.cacheRoutaFooterLogo()
            const footerLogo = pdfGeneratorManager.embeddedImages.footer
            const pdfService = pdfGeneratorManager.pdfGeneratorService
            const font = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.Helvetica)
            const fontBold = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.HelveticaBold)
            // This is used but for some reason lint does not see it
            // eslint-disable-next-line no-unused-vars
            let nextYPosition = undefined
            // Document title
            let title = this.$t('trip.report_title')
            nextYPosition -= 10
            nextYPosition = pdfService.addText(pdfDoc, title, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, ' ', font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)

            // eslint-disable-next-line
            const tripData = [
                [ // Header
                    this.$t('users.company'),
                    this.$t('trip_list.driver'),
                    this.$t('trip_list.vehicle'),
                    this.$t('trip_list.mode'),
                    this.$t('contracts.customer'),
                    this.$t('orders.contract'),
                    this.$t('vehicle_position.order'),
                    this.$t('trip_list.start_time'),
                    this.$t('trip_list.start_loc'),
                    this.$t('trip_list.end_time'),
                    this.$t('trip_list.end_loc'),
                    this.$t('trip_list.duration') + ' (H:mm)',
                    this.$t('trip_list.trip_length')
                ],
                // Data
                ...this.$refs.tripList.getTableItems().map(item => {
                    return [
                        item.company,
                        item.driver,
                        item.vehicle,
                        item.mode,
                        item.customer,
                        item.contract,
                        item.order,
                        this.toLocalTime(item.startTime),
                        item.startRoad,
                        this.toLocalTime(item.endTime),
                        item.endRoad,
                        item.duration,
                        item.length
                    ]
                })
            ]
            nextYPosition = pdfService.addTable(tripData, pdfDoc, {textFont: font, headerFont: fontBold,
                textFontSize: 8, headerFontSize: 8}, nextYPosition, undefined, undefined,
                footerLogo, 1, 1, true)
            const pdfBytes = await pdfGeneratorManager.generateBytesFromPdfDoc(pdfDoc)
            PdfGeneratorManager.downloadPdf(pdfBytes, this.$t('trip.pdf_file_name'))
            this.loading = false
        },
        closeTripWithObservationsReport() {
            this.tripReportTrip = null
        }
    }
}
</script>
